import React, { useCallback, useEffect, useMemo } from 'react';

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Box,
	Container,
	CssBaseline,
	Divider,
	IconButton,
	AppBar as MuiAppBar,
	Drawer as MuiDrawer,
	Toolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import en from 'date-fns/locale/en-GB';
import { SnackbarProvider } from 'notistack';

import { PartnerService } from '@asteria/backend-utils-services';

import Logo from '../components/Logo';
import Navigation from '../components/Navigation/Navigation';
import PDF from '../components/pdf';
import * as Context from '../context';
import * as Ducks from '../ducks';
import * as Hooks from '../hooks';
import RegisterTwoFactor from '../pages/auth/RegisterTwoFactor';
import BatchPage from '../pages/batches';
import ChatPage from '../pages/chats';
import { useSubscription as useChatSubscription } from '../pages/chats/pages/list/hooks';
import Companies from '../pages/companies';
import FeaturePage from '../pages/features';
import Home from '../pages/home/Home';
import IntegrationPage from '../pages/integrations';
import InvoiceLayoutRouter from '../pages/invoices';
import PartnersList from '../pages/partners';
import ProfileSettings from '../pages/profileSettings/ProfileSettings';
import QueuePage from '../pages/queue';
import Sync from '../pages/sync/Sync';
import Translations from '../pages/translations';
import UsageAnalytics from '../pages/usageAnalytics/UsageAnalytics';

import BreadcrumbsNavigation from './BreadcrumbsNavigation';

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

function InvoiceTest() {
	const [details, setDetails] = React.useState(null);

	React.useEffect(() => {
		axios('/invoice.json')
			.then(({ data }) => data)
			.then((data) => {
				setDetails(data);
			});
	}, []);

	return (
		<PDF
			url="/invoice.pdf"
			edit
			width={700}
			details={details}
			onSubmit={(form) => {
				console.log(form);
			}}
		/>
	);
}

const Updater = () => {
	useChatSubscription();

	return null;
};

const AuthenticatedLayout = () => {
	const { state: { accessToken } = {} } = React.useContext(
		Context.auth.Context,
	);

	const { dispatch: dispatchPartner } = React.useContext(
		Context.partner.Context,
	);

	const partnerRequestOptions = useMemo(() => {
		return {
			pageFilters: { first: 0 },
			fields: 'edges { node { _id name settings { hosting { domain widget  } } } }',
		};
	}, []);

	let location = useLocation();

	React.useEffect(() => {
		dispatchPartner(Ducks.partner.select(null));
	}, [location, dispatchPartner]);

	const { data: partnersData } = Hooks.request.useRequest({
		handler: PartnerService.partner.fetch,
		options: partnerRequestOptions,
		context: { token: accessToken },
	});

	useEffect(() => {
		if (partnersData) {
			dispatchPartner(
				Ducks.partner.fetch(partnersData.edges.map(({ node }) => node)),
			);
		}
	}, [partnersData, dispatchPartner]);

	const navigate = useNavigate();

	const home = React.useCallback(() => {
		navigate('/');
	}, [navigate]);

	const [open, setOpen] = React.useState(true);
	const toggleDrawer = useCallback(() => {
		setOpen(!open);
	}, [open]);

	return (
		<SnackbarProvider maxSnack={3} autoHideDuration={4000}>
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={en}
			>
				<Updater />
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar position="absolute" open={open}>
						<Toolbar
							sx={{
								pr: '24px', // keep right padding when drawer closed
							}}
						>
							<IconButton
								edge="start"
								color="inherit"
								aria-label="open drawer"
								onClick={toggleDrawer}
								sx={{
									marginRight: '36px',
									...(open && { display: 'none' }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<BreadcrumbsNavigation />
							<Logo
								click={home}
								className="fill-current text-white"
							/>
						</Toolbar>
					</AppBar>
					<Drawer variant="permanent" open={open}>
						<Toolbar
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer}>
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />
						<Navigation />
					</Drawer>
					<Box
						component="main"
						sx={{
							backgroundColor: (theme) =>
								theme.palette.mode === 'light'
									? theme.palette.grey[100]
									: theme.palette.grey[900],
							flexGrow: 1,
							height: '100vh',
							overflow: 'auto',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Toolbar />
						<Container
							sx={{
								flexGrow: 1,
								display: 'flex',
								flexDirection: 'column',
								my: 4,
								maxWidth: 'none !important',
							}}
						>
							<Routes>
								<Route path="/" element={<Home />} />
								<Route
									path="/registerTwoFactor"
									element={<RegisterTwoFactor />}
								/>
								<Route
									path="/UsageAnalytics"
									element={<UsageAnalytics />}
								/>
								<Route
									path="/settings"
									element={<ProfileSettings />}
								/>
								<Route
									path="/translations/*"
									element={<Translations />}
								/>
								<Route path="/sync" element={<Sync />} />
								<Route
									path="/companies/*"
									element={<Companies />}
								/>
								<Route
									path="/partners/*"
									element={<PartnersList />}
								/>
								<Route
									path="/features"
									element={<FeaturePage />}
								/>
								<Route
									path="/invoices/*"
									element={<InvoiceLayoutRouter />}
								/>
								<Route
									path="/invoice-test"
									element={<InvoiceTest />}
								/>
								<Route
									path="/batches/*"
									element={<BatchPage />}
								/>
								<Route path="/chats/*" element={<ChatPage />} />
								<Route
									path="/queue/*"
									element={<QueuePage />}
								/>
								<Route
									path="/integrations/*"
									element={<IntegrationPage />}
								/>
							</Routes>
						</Container>
					</Box>
				</Box>
			</LocalizationProvider>
		</SnackbarProvider>
	);
};

export default Context.partner.withContext(
	Context.translations.withContext(AuthenticatedLayout),
);
