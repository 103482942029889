import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid, Paper, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';

import { IntegrationGateway } from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';

/**
 * @typedef TableRowDataStatus
 * @property { string } state
 *
 * @typedef TableRowDataConfigError
 * @property { number } code
 * @property { string } message
 *
 * @typedef TableRowDataConfig
 * @property { boolean } connected
 * @property { TableRowDataConfigError[] } errors
 *
 * @typedef TableRowData
 * @property { string } _id
 * @property { string } companyId
 * @property { TableRowDataStatus } status
 * @property { string } lastSync
 * @property { string } sessionId
 * @property { TableRowDataConfig } config
 * @property { boolean } disabled
 */

/** @type { import('@mui/x-data-grid').GridColDef<TableRowData>[] } */
const COLUMNS = [
	{ field: '_id', headerName: 'ID', flex: 1 },
	{ field: 'companyId', headerName: 'Company ID', flex: 1 },
	{
		field: 'status.state',
		headerName: 'Status',
		valueGetter: ({ row }) => row.status?.state,
		width: 120,
	},
	{
		field: 'lastSync',
		headerName: 'lastSync',
		type: 'dateTime',
		valueGetter: ({ row }) =>
			row?.lastSync ? parseISO(row?.lastSync) : null,
		flex: 1,
	},
	{
		field: 'config.errors',
		headerName: 'error',
		width: 240,
		valueGetter: ({ row }) =>
			(row?.config?.errors ?? [])
				.map(({ code, message }) => `(${code}) ${message}`)
				.join('\n'),
	},
	{ field: 'sessionId', headerName: 'sessionId', flex: 1 },
];

const SLOT_PROPS = { toolbar: { showQuickFilter: true } };

const getRowId = ({ _id }) => _id;

const DataTable = React.memo(function DataTable() {
	const { type, key } = useParams();

	const accessToken = useSelector(selectToken);

	const query = useQuery({
		queryKey: ['page', 'integrations', 'details', { type, key }, 'table'],
		queryFn: async () =>
			IntegrationGateway.integration.sendRequest(
				{
					query: `
						query IntegrationStatistics(
							$filters: IntegrationFilters = {}
						) {
							integrationStatistics(filters: $filters) {
								type
								key
								data {
									_id
									companyId
									status {
										state
									}
									lastSync
									sessionId
									config {
										connected
										errors {
											code
											message
										}
									}
									disabled
								}
							}
						}
					`,
					variables: { filters: { type, key } },
				},
				{ token: accessToken },
			),

		/**
		 * @param { unknown } response
		 * @returns { TableRowData[] }
		 */
		select: (response) => {
			const statistics = response?.integrationStatistics?.[0];

			return statistics?.data ?? [];
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	return (
		<DataGrid
			disableRowSelectionOnClick
			columns={COLUMNS}
			rows={query?.data ?? []}
			loading={query?.isLoading}
			getRowId={getRowId}
			slotProps={SLOT_PROPS}
		/>
	);
});

const Page = React.memo(function Page() {
	const { type, key } = useParams();

	const accessToken = useSelector(selectToken);

	const query = useQuery({
		queryKey: ['page', 'integrations', 'details', { type, key }],
		queryFn: async () =>
			IntegrationGateway.integration.sendRequest(
				{
					query: `
						query IntegrationStatistics(
							$filters: IntegrationFilters = {}
						) {
							integrationStatistics(filters: $filters) {
								type
								key
								count
								status {
									status
									count
								}
							}
						}
					`,
					variables: { filters: { type: type, key: key } },
				},
				{ token: accessToken },
			),

		select: (response) => {
			const integration = response?.integrationStatistics?.[0];

			return {
				...integration,
				status: (integration?.status ?? []).reduce(
					(acc, { status, count }) => ({
						...acc,
						[status]: count,
					}),
					{},
				),
			};
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	return (
		<Stack
			direction="column"
			spacing={2}
			sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
		>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>ERROR</Typography>
						<Typography variant="h5">
							{query.data?.status?.ERROR ?? 0}
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs={6}>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>DISABLED</Typography>
						<Typography variant="h5">
							{query.data?.status?.DISABLED ?? 0}
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs={4}>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>IMPORTING</Typography>
						<Typography variant="h5">
							{query.data?.status?.IMPORTING ?? 0}
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs={4}>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>INITIATING</Typography>
						<Typography variant="h5">
							{query.data?.status?.INITIATING ?? 0}
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs={4}>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>IDLE</Typography>
						<Typography variant="h5">
							{query.data?.status?.IDLE ?? 0}
						</Typography>
					</Paper>
				</Grid>
			</Grid>

			<Paper sx={{ p: 2, flex: 1 }}>
				<DataTable />
			</Paper>
		</Stack>
	);
});

export default Page;
