import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useQueries, useQuery } from '@tanstack/react-query';

import { IntegrationGateway } from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';

/**
 * @typedef TableRowData
 * @property { string } type
 * @property { string } key
 * @property { number } count
 * @property { { [status: string]: number } } status
 */

/**
 *
 * @param {{ navigate: unknown }} options
 * @returns { import('@mui/x-data-grid').GridColDef<TableRowData>[] }
 */
function getDataTableColumns({ navigate }) {
	return [
		{ field: 'type', headerName: 'type', flex: 1 },
		{ field: 'key', headerName: 'key', flex: 1 },
		{ field: 'count', headerName: 'count', flex: 1 },
		{
			field: 'status.ERROR',
			headerName: 'ERROR',
			flex: 1,
			valueGetter: ({ row }) => row.status?.ERROR ?? 0,
		},
		{
			field: 'status.DISABLED',
			headerName: 'DISABLED',
			flex: 1,
			valueGetter: ({ row }) => row.status?.DISABLED ?? 0,
		},
		{
			field: 'status.INITIATING',
			headerName: 'INITIATING',
			flex: 1,
			valueGetter: ({ row }) => row.status?.INITIATING ?? 0,
		},
		{
			field: 'status.IMPORTING',
			headerName: 'IMPORTING',
			flex: 1,
			valueGetter: ({ row }) => row.status?.IMPORTING ?? 0,
		},
		{
			field: 'status.IDLE',
			headerName: 'IDLE',
			flex: 1,
			valueGetter: ({ row }) => row.status?.IDLE ?? 0,
		},
		{
			field: 'actions',
			type: 'actions',
			width: 40,
			getActions: (params) => {
				const type = params?.row?.type;
				const key = params?.row?.key;

				return [
					<GridActionsCellItem
						label="Details"
						onClick={() => navigate(`/integrations/${type}/${key}`)}
						icon={<ChevronRightIcon />}
					/>,
				];
			},
		},
	];
}

const SLOT_PROPS = { toolbar: { showQuickFilter: true } };

/** @type { import('@mui/x-data-grid').GridRowIdGetter<TableRowData> } */
const getRowId = ({ type, key }) => [type, key].join('-');

const DataTable = React.memo(function DataTable() {
	const navigate = useNavigate();

	const accessToken = useSelector(selectToken);

	const query = useQuery({
		queryKey: ['page', 'integrations', 'overview', 'table'],
		queryFn: async () =>
			IntegrationGateway.integration.sendRequest(
				{
					query: `
						query IntegrationStatistics(
							$filters: IntegrationFilters = {}
						) {
							integrationStatistics(filters: $filters) {
								type
								key
								count
                status {
                  status
                  count
                }
							}
						}
					`,
				},
				{ token: accessToken },
			),

		/**
		 * @param { unknown } response
		 * @returns { TableRowData[] }
		 */
		select: (response) =>
			(response?.integrationStatistics ?? []).map((integration) => ({
				...integration,
				status: (integration?.status ?? []).reduce(
					(acc, { status, count }) => ({ ...acc, [status]: count }),
					{},
				),
			})),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	const columns = React.useMemo(
		() => getDataTableColumns({ navigate }),
		[navigate],
	);

	return (
		<DataGrid
			disableRowSelectionOnClick
			columns={columns}
			rows={query?.data ?? []}
			loading={query?.isLoading}
			getRowId={getRowId}
			slotProps={SLOT_PROPS}
		/>
	);
});

const Page = React.memo(function Page() {
	const accessToken = useSelector(selectToken);

	const queries = useQueries({
		queries: [
			{
				queryKey: [
					'page',
					'integrations',
					'overview',
					{ type: 'bank' },
				],
				queryFn: async () =>
					IntegrationGateway.integration.sendRequest(
						{
							query: `
								query IntegrationStatistics(
									$filters: IntegrationFilters = {}
								) {
									integrationStatistics(filters: $filters) {
										type
										key
										count
									}
								}
							`,
							variables: { filters: { type: 'bank' } },
						},
						{ token: accessToken },
					),

				select: (response) =>
					(response?.integrationStatistics ?? []).reduce(
						(acc, { count }) => acc + count,
						0,
					),

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ['page', 'integrations', 'overview', { type: 'erp' }],
				queryFn: async () =>
					IntegrationGateway.integration.sendRequest(
						{
							query: `
								query IntegrationStatistics(
									$filters: IntegrationFilters = {}
								) {
									integrationStatistics(filters: $filters) {
										type
										key
										count
									}
								}
							`,
							variables: { filters: { type: 'erp' } },
						},
						{ token: accessToken },
					),

				select: (response) =>
					(response?.integrationStatistics ?? []).reduce(
						(acc, { count }) => acc + count,
						0,
					),

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ['page', 'integrations', 'overview', 'status'],
				queryFn: async () =>
					IntegrationGateway.integration.sendRequest(
						{
							query: `
								query IntegrationStatistics(
									$filters: IntegrationFilters = {}
								) {
									integrationStatistics(filters: $filters) {
										type
										key
										count
                    status {
                      status
                      count
                    }
									}
								}
							`,
							variables: {},
						},
						{ token: accessToken },
					),

				select: (response) => {
					return (response?.integrationStatistics ?? []).reduce(
						(acc, response) => {
							const statuses = response?.status ?? [];

							for (const { status, count } of statuses) {
								if (!acc[status]) {
									acc[status] = 0;
								}

								acc[status] += count;
							}

							return acc;
						},
						{},
					);
				},

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,
			},
		],
	});

	return (
		<Stack
			direction="column"
			spacing={2}
			sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
		>
			<Grid container spacing={2}>
				<Grid item xs>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>Banks</Typography>
						<Typography variant="h5">
							{queries?.[0]?.data ?? '-'}
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>ERP</Typography>
						<Typography variant="h5">
							{queries?.[1]?.data ?? '-'}
						</Typography>
					</Paper>
				</Grid>
			</Grid>

			<Paper sx={{ p: 2, flex: 1 }}>
				<DataTable />
			</Paper>
		</Stack>
	);
});

export default Page;
