import { addDays, format } from 'date-fns';

import { cleanNumber } from '../../../../../components/pdf/utils';

import convert from './operations/convert';
import formatDate from './operations/date';
import lookup from './operations/lookup';
import move from './operations/move';
import read from './operations/read';

const processor = (
	{ operations, pdfData },
	{ breakPoint = false, isArray, debug = false } = {},
) => {
	let results = '';
	let node = null;

	const breakpointOperation = operations.find(
		({ uuid }) => uuid === breakPoint,
	);

	const state = {
		switcher: false,
		break: false,
		breakpointIndex: operations.indexOf(breakpointOperation),
	};

	// If switch adjust the breakpoint to be last node in switch statement
	if (breakpointOperation && breakpointOperation.type === 'switch') {
		state.breakpointIndex = operations.findIndex(
			({ type }, index) =>
				index > state.breakpointIndex && type === 'switch',
		);

		if (state.breakpointIndex === -1) {
			state.breakpointIndex = operations.length;
		}

		state.breakpointIndex -= 1;
	}

	let i = 0;

	try {
		for (i = 0; i < operations.length; i += 1) {
			const op = operations[i];
			let operationResults = null;

			if (op.type === 'switch') {
				if (state.switcher && node) {
					break;
				}

				state.switcher = true;
			}

			if (op.type === 'test') {
				const nodeValue = (node?.text ?? '').trim();
				if (!new RegExp(op.value, 'gmi').test(nodeValue)) {
					state.break = true;
				}
			}

			if (op.type === 'lookup') {
				[operationResults, node] = lookup({
					op,
					node,
					results,
					pdfData,
				});

				if (node === null) {
					state.break = true;
				}
			}

			if (op.type === 'lookupAll') {
				const regex = new RegExp(op.pattern, 'i');

				let texts = [];

				if (op.pages === 'first') {
					texts = pdfData?.pdfData?.Pages?.[0]?.Texts ?? [];
				} else if (op.pages === 'last') {
					texts =
						pdfData?.pdfData?.Pages?.[
							pdfData?.pdfData?.Pages?.length - 1
						]?.Texts ?? [];
				} else {
					texts = (pdfData?.pdfData?.Pages ?? []).flatMap(
						({ Texts }) => Texts,
					);
				}

				if (op.direction === 'before') {
					const index = texts.indexOf(node);
					if (index !== 0) {
						texts = texts.slice(0, index);
					}

					texts = texts.toReversed();
				} else if (op.direction === 'after' && node) {
					texts = texts.slice(texts.indexOf(node) + 1);
				}

				const matches = texts.filter(({ text }) => regex.test(text));

				if (matches && matches.length > 0) {
					node = matches;
				} else {
					state.break = true;
				}
			}

			if (op.type === 'lookupAndRead') {
				[operationResults, node] = lookup({
					op,
					node,
					results,
					pdfData,
				});

				if (op.dir && node) {
					[operationResults, node] = move({ op, node, results });
				}

				if (node) {
					[operationResults, node] = read({ op, node, results });
				}

				if (node === null) {
					state.break = true;
				}
			}

			if (op.type === 'read') {
				[operationResults, node] = read({ op, node, results, pdfData });

				if (node === null) {
					state.break = true;
				}
			}

			if (op.type === 'append') {
				if (isArray) {
					operationResults = [results, node.text];
				} else {
					operationResults = `${results} ${node.text}`;
				}
			}

			if (op.type === 'sum') {
				if (Array.isArray(results)) {
					operationResults = results.reduce(
						(acc, val) =>
							acc + Number.parseFloat(cleanNumber(val ?? '')),
						0,
					);

					operationResults = operationResults.toFixed(2);
				}
			}

			if (op.type === 'set') {
				operationResults = op.value;
			}

			if (op.type === 'convert') {
				[operationResults, node] = convert({ op, node, results });
			}

			if (op.type === 'date') {
				[operationResults, node] = formatDate({ op, node, results });
			}

			if (op.type === 'adjustDate') {
				const [dateAdjust] = read({ op, node, results });
				if (dateAdjust) {
					const date = new Date(results);
					operationResults = format(
						addDays(date, parseInt(dateAdjust)),
						'yyyy-MM-dd',
					);
				}
			}

			if (op.type === 'move') {
				[operationResults, node] = move({ op, node, results });

				if (node === null) {
					state.break = true;
				}
			}

			if (op.type === 'prepend') {
				const text = Array.isArray(results)
					? results.join(' ')
					: results;
				operationResults = `${op.text} ${text}`;
			}

			if (op.type === 'filter' && results) {
				try {
					if (!Array.isArray(results)) {
						if (!new RegExp(op.filter, 'gmi').test(results)) {
							operationResults =
								op.action === 'include' ? null : results;
						} else {
							operationResults = null;
						}
					} else {
						// Is array so lets filter
						operationResults = results.filter((item) => {
							let values = [item];

							if (typeof item === 'object') {
								values = [JSON.stringify(item)];
							}

							const result = values.some((v) =>
								new RegExp(op.filter, 'gmi').test(v),
							);

							if (result && op.action === 'include') {
								return true;
							} else if (!result && op.action === 'exclude') {
								return true;
							}

							return false;
						});
					}
				} catch (e) {
					console.log(e);
					// Do nothing
				}
			}

			if (
				operationResults !== null &&
				isArray &&
				!Array.isArray(operationResults)
			) {
				results = [operationResults];
			} else if (operationResults !== null) {
				results = operationResults;
			}

			if (debug) {
				console.log(op, node, operationResults, state, results);
			}

			if (breakPoint && i >= state.breakpointIndex) {
				break;
			}

			if (state.break === true) {
				const nextSwitchIndex = operations
					.slice(i + 1)
					.findIndex(({ type }) => type === 'switch');

				if (nextSwitchIndex !== -1) {
					i += nextSwitchIndex;
					state.break = false;
					state.switcher = false;

					if (breakPoint && i >= state.breakpointIndex) {
						break;
					}
				} else {
					break;
				}
			}
		}
	} catch (e) {
		console.log(e);
		results = null;
	}

	return { results, node, breakÍndex: i };
};

export default processor;
