import React from 'react';

import { Route, Routes } from 'react-router-dom';

import DetailsPage from './details';
import OverviewPage from './overview';

function IntegrationRouting() {
	return (
		<Routes>
			<Route index element={<OverviewPage />} />
			<Route path=":type/:key" element={<DetailsPage />} />
		</Routes>
	);
}

export default IntegrationRouting;
