import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Alert } from '@mui/material';
import { useSignal } from '@preact/signals-react';
import { format, isBefore } from 'date-fns';
import { cloneDeep, get, isObject, set } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import {
	ClientService,
	CompanyService,
	InvoiceService,
} from '@asteria/backend-utils-services';

import PDF from '../../../components/pdf';
import { Language } from '../../../components/pdf/countries';
import { cleanNumber } from '../../../components/pdf/utils';
import { selectToken } from '../../../store/auth';
import { PRINTER_URL } from '../../../utils/configuration';

import PDFTextDocument from './components/pdfText';

import './styles.scss';

const getStart = (node, distance = 0) => {
	if (node.siblings.left) {
		return getStart(
			node.siblings.left.node,
			distance + node.siblings.left.distance,
		);
	}

	return {
		node,
		distance,
	};
};

const getEnd = (node, distance = 0) => {
	if (node.siblings.right) {
		return getStart(
			node.siblings.right.node,
			distance + node.siblings.right.distance,
		);
	}

	return {
		node,
		distance,
	};
};

const trimFormValues = (form) => {
	if (typeof form === 'string' || form instanceof String) {
		return form.trim();
	}

	if (!isObject(form) || Array.isArray(form)) {
		return form;
	}

	const clean = {};

	for (let key in form) {
		clean[key] = trimFormValues(form[key]);
	}

	return clean;
};

const toNumber = (num, forceNegative) => {
	const strNumber = (num?.toString?.() || '').trim();
	const value = parseFloat(
		strNumber.replace(/[\n ]/g, '').replace(/[,]/g, '.').trim(),
	);

	if (forceNegative && value > 0) {
		return -value;
	}

	return value;
};

const formatSum = (invoice) => {
	const sums = {
		...(invoice?.sums?.original ?? {
			total: 0,
			tax: 0,
			totalWithoutTax: 0,
			currency: 'SEK',
		}),
	};

	return {
		...invoice,
		sums: {
			...sums,
			total: sums.total.toString(),
			tax: sums.tax.toString(),
			totalWithoutTax:
				sums.totalWithoutTax !== 0
					? sums.totalWithoutTax
					: (sums.total - sums.tax).toString(),
		},
	};
};

function validate(value) {
	return Object.entries(AsteriaCore.utils.flatObject(value))
		.filter(([, value]) => typeof value !== 'object')
		.reduce((acc, [key, value]) => set(acc, key, value), {});
}

const formatAddress = (client) => {
	const billing = {
		...(client?.contact?.billing ?? {}),
	};

	if (billing?.zipcode) {
		billing.zipcode = billing.zipcode.replace(/ /g, '');
	}

	if (
		billing.country === 'SE' &&
		(billing.zipcode.length > 5 || billing.zipcode.length < 5)
	) {
		throw new Error('Invalid zipcode');
	}

	return validate({
		...client,
		contact: {
			...(client?.contact ?? {}),
			billing: billing,
		},
	});
};

const formatDates = (invoice) => {
	const dates = {};

	if (invoice?.dates?.invoiceDue) {
		dates.invoiceDue = format(
			new Date(invoice?.dates?.invoiceDue),
			'yyyy-MM-dd',
		);
	}

	if (invoice?.dates?.invoiceSent) {
		dates.invoiceSent = format(
			new Date(invoice?.dates?.invoiceSent),
			'yyyy-MM-dd',
		);
	}

	return {
		...invoice,
		dates: dates,
	};
};

const StaticColumns = [
	'productId',
	'description',
	'qty',
	'qtyUnit',
	'qtyAndUnit',
	'price',
	'discount',
	'total',
];

const formatRows = (rows, layout, client, invoice) => {
	const columns = invoice.columns ?? Object.values(layout?.columns ?? {});

	return rows.map((row, index) => {
		const obj = {
			type: row.type ?? 'item',
			clientType: 'CUSTOMER',
			invoiceNumber: invoice?.meta?.invoiceNumber,
			UUID: `row-${index}`,
			source: invoice?.meta?.source,
			columns: [],
			extra: Object.values(row.extra ?? {}),
		};

		columns.forEach(({ field, key }) => {
			if (!StaticColumns.includes(key)) {
				const value = row[key];
				obj.columns.push({ key: key, value: value });
			} else {
				field = field ?? key;
				let value = row[field];

				if (field === 'qtyAndUnit') {
					const lastDigitIndexRegex = /.*\d/g;
					lastDigitIndexRegex.test((value ?? '').trim());
					if (value && value.trim().lastIndexOf(' ') !== -1) {
						const unit = value
							.trim()
							.substring(value.trim().lastIndexOf(' '))
							.trim();
						value = value
							.trim()
							.substring(0, value.trim().lastIndexOf(' '));
						obj['qtyUnit'] = unit;
					} else if (
						lastDigitIndexRegex.lastIndex !== 0 &&
						lastDigitIndexRegex.lastIndex !== value.length - 1
					) {
						const unit = value
							.trim()
							.substring(lastDigitIndexRegex.lastIndex)
							.trim();
						value = value
							.trim()
							.substring(0, lastDigitIndexRegex.lastIndex);
						obj['qtyUnit'] = unit;
					}

					field = 'qty';
				}

				if (
					['qty', 'qtyAndUnit', 'tax', 'price', 'total'].includes(
						field,
					) &&
					value !== null
				) {
					value =
						value !== null && value !== undefined
							? parseFloat(
								cleanNumber(value, { allowNegative: true }),
							  )
							: null;
				}

				obj[field] = value;
			}
		});

		return obj;
	});
};

const clearFields = (invoice) => {
	const { client, rows, ...rest } = invoice;

	return rest;
};

const InvoiceLayoutPage = (props) => {
	const { className } = props;
	const { id } = useParams();
	const [layout, setLayout] = React.useState(null);
	const [invoices, setInvoices] = React.useState([]);
	const [clients, setClients] = React.useState([]);
	const [company, setCompany] = React.useState(null);
	const [version, setVersion] = React.useState(0);
	const [defaultValues, setFormValues] = React.useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const showOCR = useSignal(true);
	const accessToken = useSelector(selectToken);
	const fetch = React.useCallback(async () => {
		const response = await InvoiceService.invoice.extension.invoiceLayout(
			{ id: id },
			{ token: accessToken },
		);

		const company = await CompanyService.company.fetchOne(
			{
				id: response.companyId,
				fields: `
					id
					name
					settings {
						printTemplates {
							templateId
							config
						}
					}
			`,
			},
			{ token: accessToken },
		);

		const clients = await ClientService.client.fetch(
			{
				pageFilters: { first: 0 },
				companyId: response.companyId,
				fields: `
					edges {
						node {
							name
							type
							meta {
								clientNumber
							}
							info {
								language
								orgNumber
								vatNumber
							}
							contact {
								billing {
									street
									street2
									zipcode
									city
									country
								}

								general {
									street
									street2
									zipcode
									city
									country
								}
							}
						}
					}
				`,
			},
			{ token: accessToken },
		);

		setClients(clients?.edges?.map?.(({ node }) => node));

		const invoices = (response?.invoices ?? [])
			.filter(Boolean)
			.map(($invoice) => {
				const invoice = clearFields(
					formatDates(formatSum({ ...$invoice })),
				);

				return {
					id: $invoice.id,
					invoice: invoice,
					rows: ($invoice?.rows ?? []).map((row) => ({
						...row,
						...row.columns.reduce(
							(acc, colRow) => ({
								...acc,
								[colRow.key]: colRow.value,
							}),
							{},
						),
					})),
					client: $invoice?.client,
					isCredit: invoice?.type === 'credit',
				};
			});

		const templates = await InvoiceService.invoice.extension
			.invoiceLayoutTemplates(
				{
					pageFilters: { first: 0 },
					fields: `edges { node { _id name createdAt updatedAt layout } }`,
				},
				{ token: accessToken },
			)
			.then((response) =>
				(response?.edges ?? []).map(({ node }) => node),
			);

		const printTemplates = []
			.concat(company?.settings?.printTemplates)
			.filter(Boolean);

		const templateId = printTemplates?.[0]?.templateId;
		const template = templates.find(({ _id }) => _id === templateId);

		setDefaultTemplate(template);
		showOCR.value = !template?.layout?.operations;

		setInvoices(invoices);
		const [invoice = {}] = invoices;

		setVersion(response?.data?.version?.major ?? 1);
		// response.data = transformDataResponse(response.data);

		setLayout(response);

		setCompany(company);
		setFormValues({
			layout: response.layout,
			invoice: invoice.invoice,
			client: invoice?.client,
			companyId: response.companyId,
			isCredit: invoice?.isCredit,
			backup: {
				invoice: invoice.invoice,
				client: invoice?.client,
			},
		});
	}, [accessToken, id, showOCR]);

	React.useEffect(() => {
		fetch();
	}, [fetch]);

	const onSubmit = React.useCallback(
		async (rawForm) => {
			if (rawForm.version === 2) {
				try {
					const cleanForm = trimFormValues(rawForm);

					const country = cleanForm.client.contact.billing.country;

					const language = country
						? Language[country.toUpperCase()] ?? 'EN'
						: 'SV';
					set(cleanForm.client, 'info.language', language);
					cleanForm.client.errors = [];

					const response = await ClientService.client.update({
						input: [cleanForm.client],
						fields: `data { id contact { billing { zipcode } } }`,
						companyId: company.id,
					});

					const [{ id: clientId } = {}] = response?.data ?? [];

					if (!clientId) {
						throw new Error('Unable to add client');
					}

					await InvoiceService.invoice.extension
						.update(
							{
								input: [
									{
										...cleanForm.invoice,
										clientId: clientId,
										invoiceLayout: layout?.id,
										errors: [],
										serviceLock: false,
									},
								],
								companyId: company.id,
							},
							{ token: accessToken },
						)
						.catch((e) => {
							enqueueSnackbar(e.message, {
								variant: 'error',
							});

							throw e;
						});

					if (cleanForm.rows && cleanForm.rows.length > 0) {
						const invoiceRows = formatRows(
							cleanForm.rows,
							null,
							null,
							cleanForm.invoice,
						);

						await InvoiceService.invoice.extension
							.updateRows(
								{
									input: invoiceRows,
									companyId: company.id,
								},
								{ token: accessToken },
							)
							.catch((e) => {
								enqueueSnackbar(e.message, {
									variant: 'error',
								});

								throw e;
							});
					}

					await InvoiceService.invoice.extension.updateLayout(
						{
							id: layout?.id,
							status: 'PROCESSED',
						},
						{ token: accessToken },
					);

					await CompanyService.company.update(
						{
							id: company.id,
							input: {
								settings: {
									printTemplates: [
										{ templateId: rawForm.templateId },
									],
								},
							},
						},
						{ token: accessToken },
					);

					enqueueSnackbar(`Invoice Saved`, {
						variant: 'success',
					});
				} catch (e) {
					enqueueSnackbar(e.message, {
						variant: 'error',
					});
				}

				return;
			}

			// First create / update client data
			const form = trimFormValues(cloneDeep(rawForm));
			const companyId = form.companyId;

			const client = formatAddress({
				...form.client,
				type: 'CUSTOMER',
				meta: {
					...form.client.meta,
					source: companyId,
				},
				errors: [],
			});

			const response = await ClientService.client.update(
				{
					input: [client],
					fields: `data { id contact { billing { zipcode } } }`,
					companyId: companyId,
				},
				{ token: accessToken },
			);

			const [{ id: clientId } = {}] = response?.data ?? [];

			if (!clientId) {
				throw new Error('Unable to add client');
			}

			const isCredit = rawForm.isCredit;

			const columns = Object.values(form.layout.columns ?? {}).map(
				({ key, field, label }) => ({
					key: field !== 'text' ? field : key,
					label: label,
				}),
			);

			const invoice = validate({
				...form.invoice,
				type: rawForm.isCredit ? 'credit' : 'invoice',
				clientType: 'CUSTOMER',
				clientId: clientId,
				invoiceLayout: layout?.id,
				createdAt: undefined,
				columns: columns,
				errors: [],
				serviceLock: false,
				meta: {
					...form.invoice.meta,
					source: companyId,
					invoiceNumber: form.invoice.meta.invoiceNumber.replace(
						/ /g,
						'',
					),
				},
			});

			invoice.columns = columns;
			const rows = form?.rows
				? formatRows(
					Object.values(form?.rows),
					form.layout,
					client,
					invoice,
				  )
				: [];

			set(
				invoice,
				'sums.tax',
				toNumber(get(invoice, 'sums.tax'), isCredit),
			);
			set(
				invoice,
				'sums.totalWithoutTax',
				toNumber(get(invoice, 'sums.totalWithoutTax'), isCredit),
			);

			set(
				invoice,
				'sums.total',
				toNumber(get(invoice, 'sums.total'), isCredit),
			);

			if (form.invoice?.vats && Array.isArray(form.invoice?.vats)) {
				set(
					invoice,
					'vats',
					form.invoice?.vats.map(({ rate, total, vat }) => ({
						rate: toNumber(rate),
						total: toNumber(total, isCredit),
						vat: toNumber(vat, isCredit),
					})),
				);
			}

			if (Math.abs(invoice.sums.tax) > Math.abs(invoice.sums.total)) {
				enqueueSnackbar(`Tax amount is bigger than invoice total`, {
					variant: 'error',
				});

				return false;
			}

			if (
				Number.isNaN(invoice.sums.total) ||
				invoice.sums.total === null ||
				invoice.sums.total === undefined
			) {
				enqueueSnackbar(`Malformated total`, {
					variant: 'error',
				});

				return false;
			}

			if (
				Number.isNaN(invoice.sums.tax) ||
				invoice.sums.tax === null ||
				invoice.sums.tax === undefined
			) {
				enqueueSnackbar(`Malformated tax`, {
					variant: 'error',
				});

				return false;
			}

			if (
				isBefore(
					new Date(invoice.dates.invoiceDue),
					new Date(invoice.dates.invoiceSent),
				)
			) {
				enqueueSnackbar(`Invoice due is before invoice sent`, {
					variant: 'error',
				});

				return false;
			}

			await InvoiceService.invoice.extension
				.update(
					{
						input: [invoice],
						companyId: companyId,
					},
					{ token: accessToken },
				)
				.catch((e) => {
					enqueueSnackbar(e.message, {
						variant: 'error',
					});

					throw e;
				});

			if (rows && rows.length > 0) {
				const resp = await InvoiceService.invoice.extension
					.updateRows(
						{
							input: rows,
							companyId: companyId,
						},
						{ token: accessToken },
					)
					.catch((e) => {
						enqueueSnackbar(e.message, {
							variant: 'error',
						});

						throw e;
					});
			}

			await InvoiceService.invoice.extension.updateLayout(
				{
					id: layout?.id,
					layout: {
						data: layout?.data ?? {},
					},
					status: 'PROCESSED',
				},
				{ token: accessToken },
			);

			await CompanyService.company.update(
				{
					id: companyId,
					input: { settings: { printTemplates: [form.company] } },
				},
				{ token: accessToken },
			);

			enqueueSnackbar(`Invoice Saved`, {
				variant: 'success',
			});

			fetch();
		},
		[
			layout?.id,
			layout?.data,
			company,
			enqueueSnackbar,
			fetch,
			accessToken,
		],
	);

	const onIgnore = React.useCallback(async () => {
		await InvoiceService.invoice.extension.updateLayout(
			{
				id: layout?.id,
				layout: {},
				status: 'IGNORED',
			},
			{ token: accessToken },
		);

		enqueueSnackbar(`Invoice marked as ignored`, {
			variant: 'info',
		});
	}, [layout, enqueueSnackbar, accessToken]);

	const onProcessed = React.useCallback(async () => {
		await InvoiceService.invoice.extension.updateLayout(
			{
				id: layout?.id,
				layout: {},
				status: 'PROCESSED',
			},
			{ token: accessToken },
		);

		enqueueSnackbar(`Invoice marked as procesessed`, {
			variant: 'info',
		});
	}, [layout, enqueueSnackbar, accessToken]);

	const onRevert = React.useCallback(async () => {
		await InvoiceService.invoice.extension.updateLayout(
			{
				id: layout?.id,
				layout: {},
				status: 'PENDING',
			},
			{ token: accessToken },
		);

		enqueueSnackbar(`Invoice marked as unprocessed`, {
			variant: 'info',
		});
	}, [layout, enqueueSnackbar, accessToken]);

	const onRemove = React.useCallback(
		async ({ invoiceId, clientId, companyId }) => {
			let ok;

			ok = window.confirm(`Do you really want to remove the invoice?`);

			if (!ok) {
				return;
			}

			await InvoiceService.invoice.extension
				.remove(
					{ ids: [invoiceId], companyId: companyId },
					{ token: accessToken },
				)
				.catch((err) => {
					console.error('invoice.remove', err);
				});

			setInvoices((objects) =>
				objects.filter(
					(object) => (object?._id ?? object?.id) !== invoiceId,
				),
			);

			ok = window.confirm(`Do you want to remove a client also?`);

			if (ok) {
				await ClientService.client.extension
					.remove(
						{ ids: [clientId], companyId: companyId },
						{ token: accessToken },
					)
					.catch((err) => {
						console.error('client.remove', err);
					});
			}

			return true;
		},
		[accessToken],
	);

	const [defaultTemplate, setDefaultTemplate] = React.useState(null);

	if (layout === null || !company) {
		return null;
	}

	const uri = PRINTER_URL;

	const errors = (layout?.layout?.error ? [layout?.layout?.error] : [])
		.concat(layout?.errors ?? [])
		.concat(
			(invoices ?? [])
				.filter(Boolean)
				.flatMap(({ invoice: { errors, meta } }) =>
					errors.map((error) => ({
						...error,
						number: meta?.invoiceNumber,
					})),
				),
		)
		.map((error) => {
			if (error.message) {
				const { path, message, number } = error;
				return `${number} - ${path} - ${message}`;
			}

			return error.toString();
		});

	if (!showOCR.value) {
		return (
			<>
				{errors && errors.length > 0 ? (
					<Alert variant="filled" severity="error">
						{errors.join(', ')}
					</Alert>
				) : null}
				<PDFTextDocument
					url={`${uri}/invoices/pdf/${layout.pdfUri}`}
					id={layout?.id}
					edit
					width={700}
					defaultTemplate={defaultTemplate}
					data={layout.data}
					status={layout.status}
					form={defaultValues}
					invoices={invoices}
					clients={clients}
					company={company}
					version={version}
					onSubmit={onSubmit}
					onRevert={onRevert}
					onProcessed={onProcessed}
					onIgnore={onIgnore}
					onRemove={onRemove}
					showOCR={showOCR}
				/>
			</>
		);
	}

	return (
		<>
			{errors && errors.length > 0
				? errors.map((errors) => (
					<Alert variant="filled" severity="error">
						{errors}
					</Alert>
				  ))
				: null}
			<PDF
				url={`${uri}/invoices/pdf/${layout.pdfUri.replace('_ocr', '')}`}
				id={layout?.id}
				edit
				width={700}
				details={layout.data}
				status={layout.status}
				data={defaultValues}
				invoices={invoices}
				clients={clients}
				company={company}
				version={version}
				onSubmit={onSubmit}
				onRevert={onRevert}
				onProcessed={onProcessed}
				onIgnore={onIgnore}
				onRemove={onRemove}
				showOCR={showOCR}
			/>
		</>
	);
};

InvoiceLayoutPage.displayName = 'InvoiceLayoutPage';

InvoiceLayoutPage.propTypes = { className: PropTypes.string };

export default InvoiceLayoutPage;
