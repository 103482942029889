import { uniqueId } from 'lodash';

const getStart = (node, distance = 0) => {
	if (node.siblings.left) {
		return getStart(
			node.siblings.left.node,
			distance + node.siblings.left.distance,
		);
	}

	return {
		node,
		distance,
	};
};

const getEnd = (node, distance = 0) => {
	if (node.siblings.right) {
		return getEnd(
			node.siblings.right.node,
			distance + node.siblings.right.distance,
		);
	}

	return {
		node,
		distance,
	};
};

const getInvoicePageIdentifier = (page, selector) => {
	if (!selector || !page) {
		return null;
	}

	try {
		const rx = new RegExp(selector);

		const spans = page.blocks.flatMap(({ lines }) =>
			lines.flatMap(({ spans }) => spans.map(({ text }) => text)),
		);

		const invoiceIdentifier = spans.find((text) => rx.test(text));

		const matches = rx.exec(invoiceIdentifier);

		return matches?.[1];
	} catch (e) {
		return null;
	}
};

const transformPages = (data, settings = {}) => {
	if (!data || !data.pdfData) {
		return data;
	}

	// build matrix

	// Merge pages

	const shouldMergePages = false;
	// Waypoint multipage
	// const adjustPage = 42;
	// const skipHeader = 23;
	// const skipFooter = 20;

	const adjustPage = 25;
	const skipHeader = 8;
	const skipFooter = 18;

	let pages = data.pdfData;

	if (shouldMergePages > 0 && data.pdfData && data.pdfData.length > 1) {
		pages = [];
		let pageIndex = -1;
		let joinedPage = { ...data.pdfData[0], blocks: [] };
		let lastInvoicePageIdentifier = getInvoicePageIdentifier(
			data.pdfData[0],
			settings?.selector,
		);

		for (let i = 0; i < data.pdfData.length; i += 1) {
			const { height, blocks = [] } = data.pdfData[i];

			let skipFooterHeight = (skipFooter / 100) * height;
			let skipHeaderHeight = (skipHeader / 100) * height;
			const adjustHeight = (adjustPage / 100) * height;

			const currentInvoicePageIdentifier = getInvoicePageIdentifier(
				data.pdfData[i],
				settings?.selector,
			);

			const nextInvoicePageIdentifier = getInvoicePageIdentifier(
				data.pdfData[i + 1],
				settings?.selector,
			);

			if (
				i === 0 ||
				lastInvoicePageIdentifier !== currentInvoicePageIdentifier
			) {
				skipHeaderHeight = 0;
			}

			if (
				nextInvoicePageIdentifier !== currentInvoicePageIdentifier ||
				i === data.pdfData.length - 1
			) {
				skipFooterHeight = 0;
			}

			if (lastInvoicePageIdentifier !== currentInvoicePageIdentifier) {
				pages.push(joinedPage);
				joinedPage = { ...data.pdfData[0], blocks: [] };
				lastInvoicePageIdentifier = currentInvoicePageIdentifier;
				pageIndex = 0;
			} else {
				pageIndex += 1;
			}

			joinedPage.blocks = [
				...joinedPage.blocks,
				...blocks.map(({ lines = [] }) => {
					return {
						lines: lines.map(({ spans = [] }) => {
							return {
								spans: spans
									.map((span) => {
										if (
											height - skipFooterHeight <
											span.bbox[1]
										) {
											return null;
										}

										if (skipHeaderHeight > span.bbox[1]) {
											return null;
										}

										return {
											...span,
											bbox: [
												span.bbox[0],
												span.bbox[1] +
													pageIndex * height -
													adjustHeight * pageIndex,
												span.bbox[2],
												span.bbox[3] +
													pageIndex * height -
													adjustHeight * pageIndex,
											],
										};
									})
									.filter((item) => item),
							};
						}),
					};
				}),
			];
		}

		pages.push(joinedPage);
	}

	// pages = [data.pdfData[0]];

	return {
		...data,
		pdfData: {
			Pages: pages.map((page) => {
				const { height, width, blocks = [] } = page;
				const doMerge = true;
				// Merge spans that are close

				const splitters = (settings?.splitter ?? [])
					.map(({ selector }) => {
						if (!selector) {
							return null;
						}
						try {
							return new RegExp(selector, 'gmi');
						} catch (e) {
							return null;
						}
					})
					.filter(Boolean);

				const replacers = (settings?.replace ?? [])
					.map(({ selector, result }) => {
						if (!selector) {
							return null;
						}
						try {
							return {
								selector: new RegExp(selector, 'gmi'),
								result: result,
							};
						} catch (e) {
							return null;
						}
					})
					.filter(Boolean);

				const expanders = (settings?.expander ?? [])
					.map(({ selector }) => {
						if (!selector) {
							return null;
						}
						try {
							return new RegExp(selector, 'gmi');
						} catch (e) {
							return null;
						}
					})
					.filter(Boolean);

				const texts = blocks
					.flatMap(({ lines = [] }) => {
						const items = lines
							.flatMap(({ spans }) => {
								// Merge spans that are close
								let mergedSpans = spans.reduce((acc, item) => {
									if (acc.length === 0) {
										item.text = item.text.trim();
										return [item];
									}

									if (item.text === ' ') {
										return acc;
									}

									if (!doMerge) {
										acc.push(item);
										return acc;
									}

									const potentialMerge = acc[acc.length - 1];
									const distance =
										(item.bbox[0] -
											potentialMerge.bbox[2]) /
										width;

									if (Math.abs(distance) < 0.01) {
										acc[acc.length - 1] = {
											...potentialMerge,
											text: (
												potentialMerge.text.trim() +
												' ' +
												item.text.trim()
											).trim(),
											bbox: [
												potentialMerge.bbox[0],
												potentialMerge.bbox[1],
												item.bbox[2],
												potentialMerge.bbox[3],
											],
										};
									} else {
										acc.push(item);
									}

									return acc;
								}, []);

								if (splitters.length > 0) {
									mergedSpans = mergedSpans.reduce(
										(acc, item) => {
											for (
												let i = 0;
												i < splitters.length;
												i += 1
											) {
												const regex = splitters[i];
												regex.lastIndex = 0;
												const matches = regex.exec(
													item.text ?? '',
												);

												if (
													matches &&
													matches.length > 0
												) {
													const charWidth =
														(item.bbox[2] -
															item.bbox[0]) /
														item.text.length;

													let x = item.bbox[0];
													for (
														let j = 1;
														j < matches.length;
														j += 1
													) {
														const width =
															j ===
															matches.length - 1
																? item.bbox[2] -
																  x
																: charWidth *
																  matches[j]
																  	.length;

														acc.push({
															text: matches[j],
															bbox: [
																x,
																item.bbox[1],
																x + width,
																item.bbox[3],
															],
														});
														x = x + width;
													}

													return acc;
												}
											}

											acc.push(item);
											return acc;
										},
										[],
									);
								}

								if (replacers.length > 0) {
									mergedSpans = mergedSpans.map((item) => {
										let text = item.text;
										for (
											let i = 0;
											i < replacers.length;
											i += 1
										) {
											const { selector, result } =
												replacers[i];
											selector.lastIndex = 0;

											text = text.replace(
												selector,
												result,
											);
										}

										return {
											...item,
											text: text,
										};
									});
								}

								return mergedSpans.flatMap((item) => {
									return {
										uuid: uniqueId(),
										x: item.bbox[0],
										y: item.bbox[1],
										w: item.bbox[2] - item.bbox[0],
										h: item.bbox[3] - item.bbox[1],
										text: item.text.trim(),
										siblings: {
											start: null,
											end: null,
											up: null,
											down: null,
											left: null,
											right: null,
										},
									};
								});
							})
							.map((item) => {
								if (!item.text) {
									return item;
								}
								item.text = item.text.trim();
								return item;
							})
							.filter(({ text }) => text);

						if (!items || items.length === 0) {
							return [];
						}

						return items;
					})
					.sort(({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
						return x1 + y1 - (x2 + y2);
					});
				for (let i = 0; i < texts.length; i += 1) {
					const item = texts[i];
					if (item.siblings.right) {
						continue;
					}

					const nodeTop = item.y;
					const nodeBottom = item.y + item.h;
					const nodeCenter = {
						x: item.x + item.w / 2,
						y: item.y + item.h / 2,
					};

					for (let j = i + 1; j < texts.length; j += 1) {
						const potentialSibling = texts[j];
						const top = potentialSibling.y;
						const bottom = potentialSibling.y + potentialSibling.h;
						const center = {
							x: potentialSibling.x + potentialSibling.w / 2,
							y: potentialSibling.y + potentialSibling.h / 2,
						};

						const distance =
							Math.max(
								0,
								potentialSibling.x - (item.x + item.w),
							) / width;

						const centerYOffset = Math.abs(center.y - nodeCenter.y);

						if (nodeTop > bottom || nodeBottom < top) {
							continue;
						}

						if (centerYOffset > item.h / 2) {
							continue;
						}

						item.siblings.right = {
							node: potentialSibling,
							distance: distance,
						};

						// Expend for bottom matching
						// item.w = potentialSibling.x - item.x;

						if (!potentialSibling.siblings.left) {
							item.siblings.right.node.siblings.left = {
								node: item,
								distance: item.siblings.right.distance,
							};
						}

						break;
					}
				}

				for (let i = 0; i < texts.length; i += 1) {
					texts[i].siblings.start = getStart(texts[i]);
					texts[i].siblings.end = getEnd(texts[i]);

					if (expanders.length > 0) {
						for (let j = 0; j < expanders.length; j += 1) {
							const selector = expanders[j];
							selector.lastIndex = 0;

							if (
								selector.test(texts[i].text) &&
								texts[i].siblings.right
							) {
								texts[i].w =
									texts[i].siblings.right.node.x - texts[i].x;
							}
						}
					}
				}

				texts.sort(({ y: y1 }, { y: y2 }) => y1 - y2);

				for (let i = 0; i < texts.length; i += 1) {
					const item = texts[i];
					if (item.siblings.down) {
						continue;
					}

					const nodeLeft = item.x;
					const nodeRight = item.x + item.w;
					const itemCenter = item.y + item.h / 2;

					for (let j = i + 1; j < texts.length; j += 1) {
						const potentialSibling = texts[j];
						const left = potentialSibling.x;
						const right = potentialSibling.x + potentialSibling.w;
						const center = potentialSibling.y + potentialSibling.h / 2;

						const distance =
							Math.max(0, center - itemCenter) / height;

						if (nodeLeft > right || nodeRight < left) {
							continue;
						}

						if (
							potentialSibling.y - item.y < 0 ||
							center - itemCenter < 0
						) {
							continue;
						}

						if (potentialSibling.siblings.up) {
							potentialSibling.siblings.up.node.siblings.down =
								null;
						}

						item.siblings.down = {
							node: potentialSibling,
							distance: distance,
						};

						// Expend for bottom matching
						// item.w = potentialSibling.x - item.x;
						const index = i;
						if (potentialSibling.siblings.up) {
							i = potentialSibling.siblings.up.index - 1;
						}

						if (
							potentialSibling.siblings.up &&
							potentialSibling.siblings.up.distance <= distance
						) {
							break;
						}

						item.siblings.down.node.siblings.up = {
							node: item,
							index: index,
							distance: item.siblings.down.distance,
						};

						break;
					}
				}

				const lines = {};
				for (let i = 0; i < texts.length; i += 1) {
					const item = texts[i];
					const yCenterFloor = Math.round(
						((item.y + item.h / 4) / height) * 100,
					);

					const yCenterCeil = Math.round(
						((item.y + (item.h / 4) * 3) / height) * 100,
					);

					let yCenter = Math.round(
						((item.y + item.h / 2) / height) * 100,
					);

					/*
					if (item.text.toLowerCase() === 'info') {
						item.w = item.siblings.right.node.x - item.x;
					}
					*/
					if (lines[yCenterFloor]) {
						yCenter = yCenterFloor;
					} else if (lines[yCenterCeil]) {
						yCenter = yCenterCeil;
					}

					if (!lines[yCenter]) {
						lines[yCenter] = [];
					}

					lines[yCenter].push(item);
				}

				Object.values(lines).forEach((items) =>
					items.sort(({ x: x1 }, { x: x2 }) => x1 - x2),
				);
				return {
					Width: width,
					Height: height,
					Texts: texts,
					Lines: Object.values(lines),
				};
			}),
		},
	};
};

export default transformPages;
