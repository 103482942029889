import AsteriaCore from '@asteria/core';

import { InvoiceService } from '@asteria/backend-utils-services';

InvoiceService.invoice.extend({
	getQuery: (fields = `edges { node { id } }`) => `
		query InvoiceList(
			$pageFilters: PageInput = {}
			$filters: InvoiceFiltersInput = {}
			$companyId: ObjectId
		) {
			invoices(pageFilters: $pageFilters, filters: $filters, companyId: $companyId) {
				${fields}
			}
		}
	`,
	key: `companyInvoices`,
	loggerMethod: `services.invoiceService.companyInvoices`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.invoiceService.companyInvoices`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

InvoiceService.invoice.extend({
	getQuery: (
		fields = `edges {
		node {
			id
			status
			createdAt
			pdfUri
			company {
				id
				name
				state
			}
			errors {
				message
				path
				code
			}
			invoices {
				meta {
					invoiceNumber
				}
				createdAt
				errors {
					message
					path
					code
				}
			}
		}
	}`,
	) => `
		query GetLayouts($status: [String!], $companyId: [ID!], $startDate: Date, $endDate: Date) {
			invoiceLayouts(
				pageFilters: { first: 0 }
				filters: { status: $status, companyId: $companyId, startDate: $startDate, endDate: $endDate }
			) {
				${fields}
			}
		}
	`,
	key: `invoiceLayouts`,
	loggerMethod: `services.invoiceService.invoiceLayouts`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.invoiceService.invoiceLayouts`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

InvoiceService.invoice.extend({
	getQuery: () => `
		query GetLayout($id: ID!) {
			invoiceLayout(id: $id) {
				id
				companyId
				source
				pdfUri
				data
				status
				layout
				createdAt

				invoices {
					id
					type
					createdAt
					errors { message path code }
					client {
						_id
						name
						meta {
							clientNumber
						}
						info {
							language
							orgNumber
							vatNumber
						}
						contact {
							billing {
								street
								street2
								zipcode
								city
								country
							}
							shipping {
								street
								street2
								zipcode
								city
								country
							}
						}
					}
					contact {
						shipping {
							street
							street2
							zipcode
							city
							country
						}
					}
					sums {
						original {
							total
							totalWithoutTax
							tax
							currency
						}
					}
					vats {
						rate
						total
						vat
					}
					meta {
						invoiceNumber
						orderNumber
						sellerOrderId
						buyerOrderId
						buyerReference
						sellerReference
						termsOfPayment
					}
					delivery {
						terms
						method
						extra
					}
					dates {
						invoiceSent
						invoiceDue
					}
					print {
						summary {
							label
							value
						}
					}
					
				}
			}
		}
	`,
	key: `invoiceLayout`,
	loggerMethod: `services.invoiceService.invoiceLayout`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.invoiceService.invoiceLayout`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

InvoiceService.invoice.extend({
	getQuery: () => `
		mutation Request(
			$input: [InvoiceUpdateInputType!]!
			$companyId: ObjectId
		) {
			response: updateInvoices(input: $input, companyId: $companyId) {
				ok
				data {
					id
				}
			}
		}
	`,
	key: `update`,
	loggerMethod: `services.invoiceService.update`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.invoiceService.update`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

InvoiceService.invoice.extend({
	getQuery: () => `
		mutation Request(
			$input: [InvoiceRowUpdateInputType!]!
			$companyId: ObjectId
		) {
			response: updateInvoiceRows(input: $input, companyId: $companyId) {
				ok
			}
		}
	`,
	key: `updateRows`,
	loggerMethod: `services.invoiceService.updateRows`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.invoiceService.updateRows`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

InvoiceService.invoice.extend({
	key: `remove`,
	getQuery: () => `
		mutation DeleteInvoices($ids: [ObjectId!]!, $companyId: ObjectId) {
			deleteInvoices(ids: $ids, companyId: $companyId) {
				ok
				error
			}
		}
	`,
	loggerMethod: `services.invoiceService.remove`,
});

InvoiceService.invoice.extend({
	getQuery: () => `
		mutation Request($ids: [ID!]) {
			response: deleteInvoiceLayouts(ids: $ids) {
				ok
			}
		}
	`,
	key: `deleteLayouts`,
	loggerMethod: `services.invoiceService.deleteLayouts`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.invoiceService.deleteLayouts`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

InvoiceService.invoice.extend({
	getQuery: () => `
		mutation Request($id: ID!, $layout: JSON, $status: String) {
			response: updateInvoiceLayout(
				id: $id
				layout: $layout
				status: $status
			) {
				ok
				data {
					id
				}
			}
		}
	`,
	key: `updateLayout`,
	loggerMethod: `services.invoiceService.updateLayout`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.invoiceService.updateLayout`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

// Invoice Layout Templates

InvoiceService.invoice.extend({
	key: `invoiceLayoutTemplates`,
	getQuery: (fields = 'edges { node { _id } }') => `
		query InvoiceLayoutTemplates(
			$pageFilters: PageInput
			$filters: InvoiceLayoutTemplateFilters
		) {
			invoiceLayoutTemplates(
				pageFilters: $pageFilters
				filters: $filters
			) {
				${fields}
			}
		}
	`,
	loggerMethod: 'services.invoiceService.invoiceLayoutTemplates',
});

InvoiceService.invoice.extend({
	key: 'addInvoiceLayoutTemplate',
	getQuery: (fields = 'ok error') => `
		mutation AddInvoiceLayoutTemplate(
			$input: InvoiceLayoutTemplateAddInput!
		) {
			addInvoiceLayoutTemplate(input: $input) {
				${fields}
			}
		}
	`,
	loggerMethod: 'services.invoiceService.addInvoiceLayoutTemplate',
});

InvoiceService.invoice.extend({
	key: 'updateInvoiceLayoutTemplate',
	getQuery: (fields = 'ok error') => `
		mutation UpdateInvoiceLayoutTemplate(
			$id: ID!
			$input: InvoiceLayoutTemplateUpdateInput!
		) {
			updateInvoiceLayoutTemplate(id: $id, input: $input) {
				${fields}
			}
		}
	`,
	loggerMethod: 'services.invoiceService.updateInvoiceLayoutTemplate',
});

InvoiceService.invoice.extend({
	key: 'deleteInvoiceLayoutTemplate',
	getQuery: (fields = 'ok error') => `
		mutation DeleteInvoiceLayoutTemplate($id: ID!) {
			deleteInvoiceLayoutTemplate(id: $id) {
				${fields}
			}
		}
	`,
	loggerMethod: 'services.invoiceService.deleteInvoiceLayoutTemplate',
});

InvoiceService.invoice.extend({
	key: `update`,
	getQuery: (fields = 'ok error') => `
		mutation UpdateInvoices(
			$input: [InvoiceUpdateInputType!]!
			$companyId: ObjectId
		) {
			updateInvoices(input: $input, companyId: $companyId) {
				${fields}
			}
		}
	`,
	loggerMethod: `services.invoiceService.update`,
});

InvoiceService.invoice.extend({
	key: `remove`,
	getQuery: (fields = 'ok error') => `
		mutation DeleteInvoices($ids: [ObjectId!]!, $companyId: ObjectId) {
			deleteInvoices(ids: $ids, companyId: $companyId) {
				${fields}
			}
		}
	`,
	loggerMethod: `services.invoiceService.remove`,
});

InvoiceService.invoice.extend({
	getQuery: () => `
		mutation AddInvoiceLayoutMessage($id: ID!, $message: String!) {
			addInvoiceLayoutMessage(
				id: $id
				input: {
					message: $message
				}
			) {
				ok
				data {
					id
				}
			}
		}
	`,
	key: `addInvoiceLayoutMessage`,
	loggerMethod: `services.InvoiceService.addInvoiceLayoutMessage`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.InvoiceService.addInvoiceLayoutMessage`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

InvoiceService.invoice.extend({
	getQuery: () => `
		query GetInvoiceLayoutMessage($id: ID!) {
			invoiceLayout(id: $id) {
				messages {
					id
					userId
					userType
					message
					createdAt
				}
			}
		}
	`,
	key: `getInvoiceLayoutMessage`,
	loggerMethod: `services.InvoiceService.getInvoiceLayoutMessage`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.InvoiceService.getInvoiceLayoutMessage`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});
